



































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SessionClosingInfoProperties } from "./SessionClosingInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SessionClosingLiveSessionDialogProperties } from "./SessionClosingLiveSessionDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import DateUtil from '@/utilities/DateUtil';
import SessionClosingLiveSessionService from "@/services/SessionClosingLiveSessionService";

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false
        },
        filter: {
            liveSessionNo: ""
        },
        saving: false,
        liveSessions: []
    })
})
export default class SessionClosingLiveSessionView extends Vue {
    @Prop() private properties: SessionClosingInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
	@Prop() private sessionClosingLiveSessionDialog: SessionClosingLiveSessionDialogProperties;
    private sessionClosingLiveSessionService = new SessionClosingLiveSessionService();
    private queueHandle: number = 0;

    public get headers() {
        return [
            {
                text: this.$t("text.session-no"),
                value: "liveSession.uniqueNo"
            },
            {
                text: this.$t("text.session-date"),
                value: "sessionDate"
            },
            {
                text: this.$t("text.started-date"),
                value: "startedDate"
            },
            {
                text: this.$t("text.ended-date"),
                value: "endedDate"
            },
            {
                value: "action",
                align: "center",
                width: "60px"
            }
        ];
    }

	public isFinalized() {
		const sessionClosing = this.properties.sessionClosing;
		return sessionClosing.status === "Finalized";
	}

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public created() {
        this.properties.events.subscribe("loaded", this.load);
        this.properties.events.subscribe("load-live-session", this.load);
        this.properties.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
        this.load();
    }

    public destroyed() {
        this.properties.events.remove("loaded", this.load);
        this.properties.events.remove("load-live-session", this.load);
        this.properties.events.remove("alert-dialog-clicked", this.alertDialogClicked);
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const sessionClosing = this.properties.sessionClosing;
        if (sessionClosing && sessionClosing.id) {
            const pageNo = this.$data.table.pageNo;
            const rowCount = this.$data.table.recordCount;
            const rowStart = (pageNo - 1) * rowCount;

            this.$data.table.loading = true;
            const filter = this.$data.filter;
            try {
                const r = await this.sessionClosingLiveSessionService.get({
                    rowStart: rowStart,
                    rowCount: rowCount,
                    countRecord: true,
                    sessionClosingId: sessionClosing.id,
                    liveSessionNo: filter.liveSessionNo,
                    loadLiveSession: true
                });
                const liveSessions = r.data.sessionClosingLiveSessions;

                var count = Math.ceil(r.data.count / rowCount);
                if (count < 1) count = 1;

                this.$data.liveSessions = liveSessions.sort((lhs, rhs) => rhs.id - lhs.id);
                this.$data.table.pageCount = count;
                if (pageNo > count) {
                    this.$data.table.pageNo = count;
                }
            } catch (e) {
                if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                    var m = ExceptionUtil.getMessage(e);
                    AlertDialogProperties.error(this.alertDialog, null, m);
                    this.alertDialog.visible = true;
                }
            } finally {
                this.$data.table.loading = false;
            }
        }
    }

    public create() {
        this.sessionClosingLiveSessionDialog.visible = true;
    }

    public async remove(record: any) {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            await this.sessionClosingLiveSessionService.delete(record);
            this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public removeDialog(record: any) {
        AlertDialogProperties.delete(this.alertDialog, record.liveSession.uniqueNo);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "remove-live-session", record };
        this.alertDialog.visible = true;
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag && tag.command === "remove-live-session") {
            if (button === this.$t("text.yes")) {
                this.remove(tag.record);
            }
        }
    }
}
