var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.liveSessions,"items-per-page":_vm.table.recordCount,"page":_vm.table.pageNo,"loading":_vm.table.loading,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.isFinalized(),"icon":""},on:{"click":function($event){return _vm.create()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.add-live-session")))])])]},proxy:true},{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.liveSessionNo),callback:function ($$v) {_vm.$set(_vm.filter, "liveSessionNo", $$v)},expression:"filter.liveSessionNo"}})],1),_c('td',{attrs:{"colspan":headers.length - 1}})])]}},{key:"item.sessionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.liveSession.sessionDate))+" ")]}},{key:"item.startedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.liveSession.startedDate))+" ")]}},{key:"item.endedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.liveSession.endedDate))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":_vm.isFinalized(),"icon":""},on:{"click":function($event){return _vm.removeDialog(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.delete")))])])]}}])}),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pt-1",attrs:{"sm":"3","md":"2","lg":"2","xl":"1"}},[_c('v-select',{staticClass:"mt-4 text-center",attrs:{"label":_vm.$t('text.record-count'),"items":_vm.table.recordCounts,"dense":""},on:{"input":function($event){_vm.table.pageNo = 1; _vm.load();}},model:{value:(_vm.table.recordCount),callback:function ($$v) {_vm.$set(_vm.table, "recordCount", $$v)},expression:"table.recordCount"}})],1),_c('v-col',{staticClass:"pt-1"},[_c('v-pagination',{attrs:{"length":_vm.table.pageCount,"total-visible":"12"},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.table.pageNo),callback:function ($$v) {_vm.$set(_vm.table, "pageNo", $$v)},expression:"table.pageNo"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }